import { SplashScreen } from "./SplashScreen";
import useSignalR from "hooks/useSignalR";
import useScreen from "hooks/useScreen";
import WebGLContext, { IBubbleDetails } from "./WebGlContext";
import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import {
  getNotes,
  updateBubblePosition as apiUpdateBubblePosition,
  getLabelsMeta,
  updateLabelPos,
  createScene,
  deleteScene,
  updateScene,
  getScene,
  getSceneList,
} from "api/unity";
import { usePins } from "hooks/usePins";
import Bubble from "./Bubble";
import Note from "./Note";
import Pin from "./Pin";
import { useSearchParams } from "react-router-dom";
import useData from "hooks/useData";
import Charts from "./Charts";
import { useAuth } from "react-oidc-context";
import useApp from "hooks/useApp";
import { getData } from "./domain/getBubbleNotesUnitsEquipments";
import handleCacheControl from "./config/handleCacheControl";
import { TagExpressionHandler } from "./domain/GetUnitEquipmentforTagsExpressions";
import { loadDataFromContext } from "./domain/loadDatafromContext";
import { Item } from "types/common";
import { bubbleProps, bubbledataProps, bubblenoteProps } from "types/unitytypes";
import { equipmentProps, unitsProps } from "types/tagexpressiontypes";
import Label from "./Label";
import SceneList from "./SceneList";
import AddSceneForm from "./AddScene";
import { FiCheckCircle } from "react-icons/fi";
import useScene from "hooks/useScene";

const WebGL: React.FC = (props) => {
  const { isScaled } = useScreen();
  const { unityPins, unpinAllWidgets } = usePins();
  const { setIsLoggedIn, user } = useApp();
  // const { isScaled } = useScreen();
  const [searchParams] = useSearchParams();
  const [unityLoaded, setUnityLoaded] = useState<boolean | null>(null);



  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    isLoaded,
  } = useUnityContext({
    loaderUrl: "Build/Unity/WebGL.loader.js",
    dataUrl: "Build/Unity/WebGL.data",
    frameworkUrl: "Build/Unity/WebGL.framework.js",
    codeUrl: "Build/Unity/WebGL.wasm",
    cacheControl: handleCacheControl,
    streamingAssetsUrl: "Build/Unity/StreamingAssets",
  });

  const { unityDataHandler } = useSignalR();

  interface bubbleAllDetails{
    bubbletype:string;
    unitId:number | null;
    equipmentId: number | null;
  }
  const {
    getUnitInfo,
    getEquipmentInfo,
    getTagInfo,
    getTagsInfo,
    getEquipmentExpressionsInfo,
    getUnitExpressionsInfo,
    daySky,
    sceneId,
    profileModal,
  } = useData();
  const auth = useAuth();
  const [rerenderBubble, setRenderBubble] = useState(false);
  const [rerenderBubbleData, setRenderBubbleData] = useState(false);
  const [chartData, setChartData] = useState<Item[] | null>(null);
  const [bubbleDetails, setBubbleDetails] = useState<IBubbleDetails | null>(
    null
  );
  const [bubbleAllDetails, setbubbleAllDetails] = useState<bubbleAllDetails | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoader, setModalLoader] = useState(true);
  const [playerState, setPlayerState] = useState(false);
  const [ticketTags, updateTicketTags] = useState<number[]>([]);
  const [showLogo, setShowLogo] = useState(false);
  const [chartType, setChartType] = useState<any>(null);
  const [selectedUnit, setSelectedUnit] = useState<number | null>(null);
  const [allBubbles, setAllBubbles] = useState<bubbleProps[] | null>(null);
  const [bubblesMeta, setBubblesMeta] = useState<bubbledataProps[] | null>(null);
  const [allNotes, setAllNotes] = useState<bubblenoteProps[] | null>(null);
  const [allLabels, setAllLabels] = useState<any>(null);
  const [equipments, setEquipments] = useState<equipmentProps[] | null>(null);
  const [units, setUnits] = useState<unitsProps[] | null>(null);
  const [bubblePopUp, setBubblePopUp] = useState<any>(null);
  const [bubbleModalOpen, setBubbleModalOpen] = useState(false);
  const [popupBubbleId, setBubbleId] = useState<number | null>(null);
  const [notePopup, setNotePopup] = useState<any>(null);
  const [popupNoteId, setNoteId] = useState<number | null>(null);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [LabelPopup, setLabelPopup] = useState<any>(null);
  const [popupLabelId, setLabelId] = useState<number | null>(null);
  const [LabelModalOpen, setLabelModalOpen] = useState(false);
  const [showPins, setShowPins] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(
    "Loading data from the network"
  );
  const [unityPrefabLoading, setUnityPrefabLoading] = useState(0);
  const [unityLoadingProgress, setUnityLoadingProgress] = useState(0);
  const [fakeLoading, setFakeLoading] = useState(false);
  const [unpinConfirmation, setUnpinConfirmation] = useState(false);

  /* STATES RELATED TO ADD/EDIT/DELETE SCENES */
  const [AllScenesModalOpen, setAllScenesModalOpen] = useState(false);
  const [SceneHandlerModalOpen, setSceneHandlerModalOpen] = useState(false);
  const {        
    AllScenesList,
    setAllScenesList,
    CurrentSceneId,
    setCurrentSceneId,
    Loadmessage,
    setLoadmessage,
    EditSceneFormData,
    setEditSceneFormData,
    CurrentSceneData, 
    setCurrentSceneData,
    GetAllScenes,
    LoadScene} = useScene();

  const baseUrl = localStorage.getItem("baseUrl");
  const webglUrl = localStorage.getItem("webglUrl");
  const webglPort = localStorage.getItem("webglPort");

  function unityLoadingInterval() {
    const intervalId = setInterval(() => {
      setUnityLoadingProgress((prevProgress) => {
        if (prevProgress !== 99) {
          return prevProgress + 1;
        } else {
          clearInterval(intervalId);
          return prevProgress;
        }
      });
    }, 3000);
  }
  useEffect(() => {
    if (!fakeLoading) {
      if (unityPrefabLoading < 90) {
        setUnityLoadingProgress(unityPrefabLoading);
      } else if (unityPrefabLoading === 90) {
        setUnityLoadingProgress(90);
        unityLoadingInterval();
        setFakeLoading(true);
      }
    }
  }, [unityPrefabLoading]);

  useEffect(() => {
    (async () => {
      const data = await getData();
      setBubblesMeta(data.BubblesMeta);
      setAllBubbles(data.BubblesData);
      setAllNotes(data.Notes);
      setEquipments(data.Equipments);
      setUnits(data.Units);
      setAllLabels(data.LabelsMeta);

      sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
      sendMessage("MainLabel", "SetLabelsList", JSON.stringify(allLabels));
    })();
  }, []);

  const refreshUnityData = async () => {
    const data = await getData(true);
    setBubblesMeta(data.BubblesMeta);
    setAllBubbles(data.BubblesData);
    sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
    sendMessage("MainBubble", "SetBubbleData", JSON.stringify(allBubbles));
  };

  useEffect(() => {
    sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
    sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
    sendMessage("MainBubble", "SetBubbleData", JSON.stringify(allBubbles));
    sendMessage("MainLabel", "SetLabelsList", JSON.stringify(allLabels));
  }, [allBubbles, bubblesMeta, allNotes, allLabels]);

  const unityProgressBarHandler: any = useCallback((amount: number) => {
    const loadingAmount = Math.round(90 * amount);
    setUnityPrefabLoading(loadingAmount);
  }, []);

  const updateNotes = async () => {
    const notesResponse = await getNotes();
    if (notesResponse.status === 200) {
      setAllNotes(notesResponse.data);
    }
    sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
  };

  const updateLabels = async () => {
    const LabelsResponse = await getLabelsMeta();
    if (LabelsResponse.status === 200) {
      setAllLabels(LabelsResponse.data);
    }
    sendMessage("MainLabel", "SetLabelsList", JSON.stringify(allLabels));
  };

  useEffect(() => {
    if (isLoaded) {
      setLoadingStatus(
        "We are building your digital twin, it will take from 30 seconds till 4 min depends on graphic card"
      );
    }
  }, [isLoaded]);

  const unityReadyHandler = useCallback(() => {
    setUnityLoaded(true);
    setShowLogo(true);
    setTimeout(() => {
      setShowLogo(false);
    }, 5000);
    if (baseUrl) {
      sendMessage("Utilities", "SetBackendUrl", baseUrl);
    }
    if (webglUrl && webglPort) {
      const webglserverurl = { webglUrl: webglUrl, webglPort: webglPort };
      sendMessage(
        "Utilities",
        "SetWebGlServerUrl",
        JSON.stringify(webglserverurl)
      );
    }
  }, [sendMessage]);

  useEffect(() => {
    if (unityLoaded) {
      document.getElementById("navWrp")?.classList?.add("!static");
      setTimeout(() => {
        const bubbleId = searchParams.get("bubbleId");
        if (bubbleId) {
          sendMessage("Utilities", "MoveUserToBubble", parseInt(bubbleId));
        }
      }, 5000);
    } else {
      document.getElementById("navWrp")?.classList?.remove("!static");
    }
  }, [unityLoaded, sendMessage]);

  useEffect(() => {
    setRenderBubble(true);
  }, [allBubbles]);

  useEffect(() => {
    if (unityLoaded) {
      sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
    }
  }, [unityLoaded]);

  const sendBubbleData = useCallback(
    (reload: boolean) => {
      if (unityLoaded) {
        const dataCollection: any = loadDataFromContext(
          allBubbles,
          getUnitInfo,
          getEquipmentInfo,
          getTagInfo
        );
        const bubbleAndData = {
          rerender: reload,
          bubbles: dataCollection,
        };
        sendMessage("MainBubble", "SetBubbleData", JSON.stringify(allBubbles));
      }
    },
    [unityLoaded, sendMessage, loadDataFromContext]
  );

  useEffect(() => {
    if (unityLoaded && rerenderBubble) {
      sendBubbleData(true);
      setRenderBubble(false);
    }
    // eslint-disable-next-line
  }, [rerenderBubble, sendBubbleData, unityLoaded]);

  useEffect(() => {
    setRenderBubbleData(true);
  }, [units]);

  useEffect(() => {
    if (unityLoaded && rerenderBubbleData) {
      sendBubbleData(false);
      setRenderBubbleData(false);
    }
    // eslint-disable-next-line
  }, [rerenderBubbleData, sendBubbleData, unityLoaded]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (unityLoaded) {
      if (allNotes) {
        const notesCollection = allNotes.map((eachNote: any) => {
          const { id, x, y, z, header, note } = eachNote;
          return { id, x, y, z, header, note };
        });
        sendMessage(
          "MainNote",
          "SetNotesList",
          JSON.stringify(notesCollection)
        );
      } else {
        sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
      }
    }
    // eslint-disable-next-line
  }, [allNotes, unityLoaded]);

  useEffect(() => {
    if (unityLoaded) {
      if (allLabels) {
        const LabelsCollection = allLabels.map((eachLabel: any) => {
          const { id, x, y, z, prefabId, prefabParentId, name } = eachLabel;
          return { id, x, y, z, prefabId, prefabParentId, name };
        });
        sendMessage(
          "MainLabel",
          "SetLabelsList",
          JSON.stringify(LabelsCollection)
        );
      } else {
        sendMessage("MainLabel", "SetLabelsList", JSON.stringify(allLabels));
      }
    }
    // eslint-disable-next-line
  }, [allLabels, unityLoaded]);


  const handleTogglePins = useCallback((status: any) => {
    if (status === 3) {
      setShowPins(false);
    }
    if (status !== 3) {
      setShowPins(true);
    }
  }, []);

  const createBubble: any = useCallback((x: number, y: number, z: number) => {
    const bubbleDetails = {
      x,
      y,
      z,
      unitId: null,
      equipmentId: null,
    };
    setBubblePopUp(bubbleDetails);
    setBubbleModalOpen(true);
    setBubbleId(null);
  }, []);

  const updateBubblePosition: any = useCallback(async (bubbles: any) => {
    if (bubbles) {
      const data = JSON.parse(bubbles);
      const response: any = await apiUpdateBubblePosition(data);
      if (response.status === 200) {
        refreshUnityData();
      }
    }
  }, []);

  const configureBubbleHandler: any = useCallback((bubbleId: any) => {
    if (bubbleId) {
      setBubblePopUp(null);
      setBubbleModalOpen(true);
      setBubbleId(bubbleId);
    }
  }, []);

  const createNote: any = useCallback((x: number, y: number, z: number) => {
    const noteDetails = { x, y, z };
    setNotePopup(noteDetails);
    setNoteId(null);
    setNoteModalOpen(true);
  }, []);

  const openNote: any = useCallback((noteId: number) => {
    setNotePopup(null);
    setNoteId(noteId);
    setNoteModalOpen(true);
  }, []);


  const createLabel: any = useCallback((x: number, y: number, z: number) => {
    const LabelDetails = { x, y, z };
    setLabelPopup(LabelDetails);
    setLabelId(null);
    setLabelModalOpen(true);
  }, []);

  const openLabel: any = useCallback((LabelId: number) => {
    setLabelPopup(null);
    setLabelId(LabelId);
    setTimeout(() => {
      setLabelModalOpen(true);
    }, 500);
  }, []);


  const updateLabelPosition: any = useCallback(async (labels: any) => {
    if (labels) {
      const data = JSON.parse(labels);
      const response = await updateLabelPos(data);
      if (response.status === 200) {
        const labelsMetaResponse = await getLabelsMeta();
        if (labelsMetaResponse.status === 200) {
          setAllLabels(labelsMetaResponse.data);
        }
      }
    }
  }, []);

  const fetchLatestData = useCallback(() => {
    sendMessage("UpdateBubbles", "UpdateFromSignalR");
  }, [sendMessage]);

  useEffect(() => {
    if (unityLoaded) {
      sendMessage("Utilities", "updateSkyBox", daySky);
    }
  }, [unityLoaded, daySky, sendMessage]);

  const tagsAndBubbleConfigHandler: any = useCallback(
    async (bubbleId: number, equipmentId: number, type: string) => {
      const bubbleTagDetails: any = await TagExpressionHandler(
        bubbleId,
        equipmentId,
        type,
        "tag"
      );

      if (type === "unconfigured") {
        setSelectedUnit(null);
        configureBubbleHandler(bubbleId);
      } else {
        setbubbleAllDetails({bubbletype:type,unitId:bubbleTagDetails.unitId,equipmentId:type=="equipment"?equipmentId:null});
        setSelectedUnit(bubbleTagDetails.unitId);
        setBubbleDetails(bubbleTagDetails.details);
        if (
          bubbleTagDetails.collections &&
          bubbleTagDetails.collections.length > 0
        ) {
          setChartData(bubbleTagDetails.collections);
          setModalOpen(true);
          setModalLoader(false);
          setBubbleId(bubbleId);
        } else {
          configureBubbleHandler(bubbleId);
        }
      }
    },
    [getTagsInfo, getEquipmentInfo, configureBubbleHandler, equipments]
  );

  const expressionsHandler: any = useCallback(
    async (bubbleId: number, assertId: number, type: string) => {
      const bubbleExpressionDetails: any = await TagExpressionHandler(
        bubbleId,
        assertId,
        type,
        "expression"
      );
      setSelectedUnit(bubbleExpressionDetails.unitId);
      setBubbleDetails(bubbleExpressionDetails.details);
      setbubbleAllDetails({bubbletype:type,unitId:bubbleExpressionDetails.unitId,equipmentId:type=="equipment"?assertId:null});
      if (
        bubbleExpressionDetails.collections &&
        bubbleExpressionDetails.collections.length > 0
      ) {
        setChartData(bubbleExpressionDetails.collections);
        setModalOpen(true);
        setModalLoader(false);
        setBubbleId(bubbleId);
      } else {
        configureBubbleHandler(bubbleId);
      }
    },
    [
      getEquipmentExpressionsInfo,
      getUnitExpressionsInfo,
      getUnitInfo,
      getEquipmentInfo,
      configureBubbleHandler,
      units,
      equipments,
    ]
  );

  const logOut = useCallback(() => {
    (async () => {
      auth.signoutRedirect();
      setIsLoggedIn("loggedOut");
    })();
  }, []);

  const handlePlayerState: any = useCallback((state: boolean) => {
    setPlayerState(state);
  }, []);

  /* When WebGL component unmounts (in cleanup function) - enable the browser keyboard inputs */
  useEffect(() => {
    return () => {
      if (unityLoaded) {
        pauseUnity();
      }
    };
  }, [unityLoaded]);

  useEffect(() => {
    switch (sceneId) {
      case 1:
        sendMessage("Scene Two", "Loadscene");
        break;
      case 2:
        sendMessage("Scene Three", "Loadscene");
        break;
      default:
        break;
    }
  }, [sendMessage, sceneId]);

  /* FUNCTIONS RELATED TO SCENES*/

  const DeleteScene:any = async(id:any)=>{
    const response = await deleteScene(id);
    if (response.status===200){
      console.log("Scene Deleted");
      GetAllScenes();
    }
  };

  useEffect(() => {
    if(unityLoaded && CurrentSceneData){
      const data:any={Id:CurrentSceneData.id,Data:CurrentSceneData.unityJson};
      sendMessage("__AssetsMapHandler", "LoadFromReact",JSON.stringify(data));
    }
  }, [unityLoaded,CurrentSceneData]);

  useEffect(() => {
    if (AllScenesModalOpen){
      GetAllScenes();
    }
  }, [AllScenesModalOpen]);

  const LoadSceneModal:any = useCallback(()=>{
    if(isLoaded){
      setAllScenesModalOpen(true);
    }
  },[isLoaded]);


  const handleCloseSceneListModal = ()=>{
    setAllScenesModalOpen(false);
  };

  const CloseSceneHandlerModal = ()=>{
    setSceneHandlerModalOpen(false);
    setEditSceneFormData(null);
  };

  const getSceneDetails = async(id:any) =>{
    const response = await getScene(id);
    if (response.status===200){
      setEditSceneFormData(response.data);
    }
    setSceneHandlerModalOpen(true);
  };

  const UpdateScene:any = useCallback(async(unityJson:string)=>{
    console.log("SELECTEDID",CurrentSceneId);
    if (CurrentSceneData && CurrentSceneId){
      const name = CurrentSceneData.name;
      const desc = CurrentSceneData.desc;
      const response = await updateScene({id:CurrentSceneId,name:name,desc:desc,unityJson:unityJson});
      if (response.status===200){
        console.log("Scene Saved");
      }
    }
  },[]);

  useEffect(() => {
    if(Loadmessage && unityLoaded){
      setTimeout(() => {
        setLoadmessage(null);
      }, 3000);
    }
  }, [Loadmessage, unityLoaded]);

  useEffect(
    function () {
      addEventListener("UnityIsReady", unityReadyHandler);
      addEventListener("SaveAssetsData", UpdateScene);
      addEventListener("LoadAssetsData", LoadSceneModal);
      // addEventListener("RemoveAssetsData", DeleteScene);

      addEventListener("TriggerOnLeftClick", tagsAndBubbleConfigHandler);
      addEventListener("TriggerOnRightClick", expressionsHandler);
      addEventListener("PlayerState", handlePlayerState);
      addEventListener("makeLogOut", logOut);

      addEventListener("NewBubbleAdded", createBubble);
      addEventListener("UpdateBubblePosition", updateBubblePosition);
      addEventListener("NewNoteAdded", createNote);
      addEventListener("openNote", openNote);
      addEventListener("R_OnChangeCameraType", handleTogglePins);
      addEventListener("NewLabelAdded", createLabel);
      addEventListener("openLabel", openLabel);
      addEventListener("UpdateLabelPosition", updateLabelPosition);
      addEventListener("unityProgressBar", unityProgressBarHandler);
      return () => {
        removeEventListener("UnityIsReady", unityReadyHandler);
        removeEventListener("unityProgressBar", unityProgressBarHandler);
        removeEventListener("TriggerOnLeftClick", tagsAndBubbleConfigHandler);
        removeEventListener("TriggerOnRightClick", expressionsHandler);
        removeEventListener("PlayerState", handlePlayerState);
        removeEventListener("makeLogOut", logOut);
        removeEventListener("R_OnChangeCameraType", handleTogglePins);
        removeEventListener("NewBubbleAdded", createBubble);
        removeEventListener("NewNoteAdded", createNote);
        removeEventListener("NewLabelAdded", createLabel);
        removeEventListener("openLabel", openLabel);
        removeEventListener("UpdateLabelPosition", updateLabelPosition);
        removeEventListener("SaveAssetsData", UpdateScene);
        removeEventListener("LoadAssetsData", LoadSceneModal);
        // removeEventListener("RemoveAssetsData", DeleteScene);
      };
      // eslint-disable-next-line
    },
    [tagsAndBubbleConfigHandler, expressionsHandler, unityProgressBarHandler,isLoaded]
  );

  useEffect(
    function () {
      if (unityLoaded) {
        unityDataHandler(fetchLatestData);
      }
    },
    [unityLoaded, fetchLatestData]
  );

  useEffect(
    function () {
      if (unityLoaded) {
        sendMessage("UserDetails", "setUserDetails", JSON.stringify(user));
      }
    },
    [unityLoaded]
  );

  /* resume unity when closing the modal/popup */
  const resumeUnity = useCallback(() => {
    if (unityLoaded) {
      sendMessage("__ReactInputBlocker", "SetBlockState", 0);
    }
  }, [unityLoaded, sendMessage]);

  /* PauseUnity - will enable keyboard inputs */
  const pauseUnity = useCallback(() => {
    sendMessage("__ReactInputBlocker", "SetBlockState", 1);
  }, [sendMessage]);

  useEffect(() => {
    if(SceneHandlerModalOpen){
      sendMessage("__ReactInputBlocker", "SetBlockState", 1);
    }
    else{
      sendMessage("__ReactInputBlocker", "SetBlockState", 0);
    }
  }, [SceneHandlerModalOpen]);
  
  useEffect(() => {
    if (noteModalOpen) {
      sendMessage("Utilities", "PauseGameFromReact");
    } else {
      sendMessage("Utilities", "ResumeGame");
    }
  }, [noteModalOpen]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setBubbleId(null);
    resumeUnity();
  }, [resumeUnity]);

  const handleModalLoader = useCallback(() => {
    pauseUnity();
    setModalLoader(false);
  }, [pauseUnity]);

  const ticketTagsHandler = useCallback((tagId: number) => {
    updateTicketTags((state) => {
      const newList = [...state];
      newList.push(tagId);
      return newList;
    });
  }, []);

  const LabelCloseHandler = useCallback(() => {
    setLabelModalOpen(false);
    resumeUnity();
  }, [resumeUnity]);

  useEffect(() => {
    if (unityLoaded) {
      if (profileModal) {
        pauseUnity();
      } else {
        resumeUnity();
      }
    }
  }, [unityLoaded, profileModal, pauseUnity, resumeUnity]);

  const bubbleCloseHandler = useCallback(() => {
    setBubbleModalOpen(false);
    setBubblePopUp(null);
    resumeUnity();
    setbubbleAllDetails(null);
  }, [resumeUnity]);

  const noteCloseHandler = useCallback(() => {
    setNoteModalOpen(false);
    resumeUnity();
  }, [resumeUnity]);

  return (
    <WebGLContext.Provider
      value={{
        resumeUnity,
        pauseUnity,
        chartData,
        bubbleDetails,
        handleModalLoader,
        handleCloseModal,
        modalOpen,
        modalLoader,
        ticketTags,
        ticketTagsHandler,
        popupBubbleId,
        configureBubbleHandler,
        chartType,
        setChartType,
        refreshUnityData,
        bubbleAllDetails
      }}
    >
      {modalOpen && chartData && <Charts setModalOpen={setModalOpen} />}

      {AllScenesModalOpen && AllScenesList && <SceneList handleCloseModal={handleCloseSceneListModal} setSceneHandlerModalOpen={setSceneHandlerModalOpen} pauseUnity={pauseUnity} DeleteScene={DeleteScene} getSceneDetails={getSceneDetails}/>}

      {bubbleModalOpen && (
        <Bubble
          bubbleId={popupBubbleId}
          modalOpen={bubbleModalOpen}
          bubbleDetails={bubblePopUp}
          closeHandler={bubbleCloseHandler}
          updateBubbles={refreshUnityData}
          units={units}
          equipments={equipments}
          pauseUnity={pauseUnity}
          resumeUnity={resumeUnity}
          selectedUnit={selectedUnit}
        />
      )}

      {noteModalOpen && (
        <Note
          noteId={popupNoteId}
          modalOpen={noteModalOpen}
          noteDetails={notePopup}
          closeHandler={noteCloseHandler}
          updateNotes={updateNotes}
          getData={getData}
          pauseUnity={pauseUnity}
          resumeUnity={resumeUnity}
        />
      )}

      {Loadmessage && <div className="z-[9999] text-xl absolute  h-7 w-fit px-2 left-[40%] top-[45%] rounded-md bg-green bg-opacity-50 flex">{Loadmessage}<FiCheckCircle className="ml-2 mt-1" /></div>}

      {LabelModalOpen && (
        <Label
          LabelId={popupLabelId}
          modalOpen={LabelModalOpen}
          LabelDetails={LabelPopup}
          closeHandler={LabelCloseHandler}
          updateLabels={updateLabels}
          pauseUnity={pauseUnity}
          resumeUnity={resumeUnity}
          allLabels={allLabels}
        />
      )}

      {SceneHandlerModalOpen && (
        <AddSceneForm   closeHandler={CloseSceneHandlerModal} pauseUnity={pauseUnity}/>
      )}


      {showPins && unityPins && unityPins.length > 0 && (
        <div className="absolute right-0 2xl:right-2 bottom-0 2xl:bottom-3 rounded-md 3xl:p-3 p-1 flex flex-col gap-1 2xl:gap-3">
          {unityPins.slice(0, 6).map((pin: any) => (
            <Pin key={pin.id} data={pin} unityPin />
          ))}
          <div className="flex items-end justify-end h-max w-full">
            {!unpinConfirmation && <button className="btn-danger" onClick={() => setUnpinConfirmation(true)}>Unpin All Tags</button>}
            {unpinConfirmation &&
              <div className="flex gap-5">
                <button className="btn-sec" onClick={() => setUnpinConfirmation(false)}>Keep</button>
                <button className="btn-danger" onClick={() => {
                  unpinAllWidgets(2);
                  setUnpinConfirmation(false);
                }}>Remove</button>
              </div>}
          </div>
        </div>
      )}
      {/* adding margin of navigation size */}
      <div
        id="unity-container"
        className={`w-full h-full`}
      >
        <SplashScreen
          open={!unityLoaded || showLogo}
          logo={true}
          status={loadingStatus}
          progressStatus={unityLoadingProgress}
        // isScaled={isScaled}
        />
        <Unity
          unityProvider={unityProvider}
          className="unityApp"
          style={{
            width: "100%",
            height: "100%",
            visibility: unityLoaded || showLogo ? "visible" : "hidden",
          }}
          devicePixelRatio={isScaled ? 1.308 : 1}
        />
      </div>
    </WebGLContext.Provider>
  );
};

export default WebGL;